// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import { Container } from '../../components/basic/Grid'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
// import Products from '../../components/Products'
import IntroTeaser from '../IntroTeaser'
// import Flexibility from './Flexibility'
import Services from './Services'
import Scrollindicator from '../../components/basic/Scrollindicator'
import ClientOverview from '../Clients/ClientOverview'

import messages from './ServicesPage.lang'
import baseStyles from './ServicesPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

const IntroImage = (
  <StaticImage src="../../images/pictures/services_intro_image.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/service_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const ServicesPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="office gallery" />
      <Container fluid>
        <IntroTeaser
          title={formatMessage(messages.title)}
          copy={
            <>
              <p>{formatMessage(messages.copyP1)}</p>
              <p>{formatMessage(messages.copyP2)}</p>
            </>
          }
          image={IntroImage}
          styles={styles.intro}
        />
        {/* <Flexibility /> */}
        <Services />
      </Container>
      <ClientOverview />
      {/* <Products /> */}
      <Scrollindicator />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ServicesPage)
