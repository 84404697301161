/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  pageTitle: 'Services',
  title: 'Our Mission: Your Digital Success',
  copyP1: 'Are you starting out or are you already an established player in your market segment? We deliver and develop the system environment needed to increase your long-term success. Together with you, we digitize your individual processes. A team of specialists is with you from the start. Naturally, we also work in cooperation with your existing partners.',
  copyP2: 'Average is easy. We raise the bar. We think and work digitally and love the internet. We listen, complete, combine, and find solutions that complete and optimize your system landscape: fast, effectively, and in perfect quality.'
})
