// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import ScrollAnimation from 'react-animate-on-scroll'
import { Row, Col } from '../../../components/basic/Grid'
import ListItem from '../../../components/basic/ListItem'
import { servicesList } from '../../Services/services'
import messages from './Services.lang'
import baseStyles from './Services.scss'

type Props = {
  ...StyleProps,
  services?: Array<string>
}

const Services = ({
  styles,
  services = [
    'consulting',
    'conceptStrategy',
    'design',
    'development',
    'systemIntegration',
    'systemEngineering',
    'appDevelopment',
    'hcmsIntegration'
  ]
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Row className={styles.list}>
        {services.map((item, i) => (
          <Col md="6" lg="4" className={styles.col} key={i}>
            <ScrollAnimation
              className={styles.animationContainer}
              animateIn="fadeInUp"
              animatePreScroll={false}
              animateOnce
            >
              <ListItem
                img={servicesList[item].img}
                styles={styles.item}
                title={formatMessage(servicesList[item].title)}
                btn={formatMessage(messages.learnMoreBtn)}
                btnLink={servicesList[item].localLink}
                info={
                  servicesList[item].info && (
                    <ul>
                      {servicesList[item].info.map((infoItem, i) => (
                        <li key={i}>{formatMessage(infoItem)}</li>
                      ))}
                    </ul>
                  )
                }
              />
            </ScrollAnimation>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Services)
